@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url('../../public/fonts/NunitoSans-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
